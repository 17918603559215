import React, { useState, useEffect,useContext } from 'react';
import { useRegister } from './useRegister';
import PhoneNumber from '../../../components/PhoneNumber';
import { TfiEye } from "react-icons/tfi";
import { FaRegEyeSlash } from "react-icons/fa";

import { useLocation } from 'react-router-dom';
import { RegisterContext } from '../../../context/RegisterContext';

import "./register.css";


function Register() {
   const { check, isLoading, error, setError } = useRegister();
   const {state} = useLocation();
const { errorMsg } = state || {}; // Read values passed on state

  /* const [name, setName] = useState('')
   const [phoneNumber, setPhoneNumber] = useState('')
   const [dialingCode, setDialingCode] = useState('')*/
   const { phoneNumber, setPhoneNumber, dialingCode, setDialingCode } = useContext(RegisterContext);
   const [password, setPassword] = useState('')
   const [confirmPassword, setConfirmPassword] = useState('')

   //pw inp type
   const [isShown, setIsSHown] = useState({
      password: false,
      cPassword: false
   });
   const togglePassword = (field) => {
      if (field === 'password') {
         setIsSHown({ ...isShown, password: !isShown.password });
      }
      if (field === 'cPassword') {
         setIsSHown({ ...isShown, cPassword: !isShown.cPassword });
      }
   };


   useEffect(() => {
      if (errorMsg) {
         setPhoneNumber(phoneNumber);
         setDialingCode(dialingCode);
      }
      else{
         setPhoneNumber("");
         setDialingCode("");
      }
    }, []);

   //handle next click
   const handleSubmit = async (e) => {
      e.preventDefault();
      if (password === confirmPassword) {
         await check(dialingCode, phoneNumber, password)
      } else {
         setError('Confirm password is Not Matched.')
      }
   }


   return (
      <>
         <div className='form-register-wrapper'>
            <h2 className='mb-4 text-end'>Sign Up</h2>
            <form className='form-register' onSubmit={handleSubmit}>
               <div className="mb-3 requiredMark">
                  <PhoneNumber value={{ phoneNumber, dialingCode }}
                     onChange={{ setPhoneNumber, setDialingCode }} inputDataType="number"
                     labelText="Phone Number" />
               </div>
               <div className="mb-3">
                  <div className="d-flex">
                     <div className="floating-control requiredMark">
                        <input type={isShown.password ? "text" : "password"} name="password" id="password" className="floating-input"
                           placeholder="none" autoComplete='off' required
                           onChange={(e) => setPassword(e.target.value)} value={password} />
                        <label htmlFor="password" className="floating-label">Password</label>
                     </div>
                     <span className="input-group-text fs-4 cursor-pointer" onClick={() => togglePassword('password')}>
                        {isShown.password ? <FaRegEyeSlash /> : <TfiEye />}
                     </span>
                  </div>

               </div>
               <div className="mb-3">
                  <div className="d-flex">
                     <div className="floating-control requiredMark">
                        <input type={isShown.cPassword ? "text" : "password"} name="confirmPassword" id="confirmPassword" className="floating-input"
                           placeholder="none" autoComplete='off' required
                           onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} />
                        <label htmlFor="password" className="floating-label">Confirm Password</label>
                     </div>
                     <span className="input-group-text fs-4 cursor-pointer" onClick={() => togglePassword('cPassword')}>
                        {isShown.cPassword ? <FaRegEyeSlash /> : <TfiEye />}
                     </span>
                  </div>

               </div>

               <button disabled={isLoading} type="submit" className="btn btn-red btn-register mt-1 mx-auto d-block"
               >Next</button>
                {error && <p className='text-danger text-center'>{error}</p>}
                {errorMsg && <p className='text-danger text-center'>{errorMsg}</p>}
            </form>
         </div>
      </>
   )
}

export default Register;