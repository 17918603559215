import React, { useEffect, useState } from 'react'
import OTPInput from "otp-input-react";
import axios from 'axios';
import getBaseUrl from '../../../../hooks/http'
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { useLogout } from '../../../../hooks/useLogout';
import { TfiEmail } from "react-icons/tfi";
import { BsWhatsapp } from "react-icons/bs";






const EmpOtpForm = ({ data, updateFields, changeField, setcurrentStepIndex }) => {
   const { user } = useAuthContext();
   const { logout } = useLogout()

   const [error, setError] = useState('')
   const [OTP, setOTP] = useState('');

   useEffect(() => {
      updateFields({ otp: OTP });
   }, [OTP])

   //handle form submit event --------------
   const handleFormSubmit = async (e) => {
      e.preventDefault();
      const confirm_otp = localStorage.getItem("otp");

      if (data.otp === '') {
         setError('OTP is required.')
      } else {
         if (confirm_otp === data.otp) {
            updateAccount()
         } else {
            setError('Invalid OTP.')
            setOTP('')
         }
      }
   }
   const updateAccount = async () => {
      try {
         let config = {
            headers: {
               Authorization: `Bearer ${user.token}`,
            },
         };
         const body = {
            dialingCode: data.dialingCode, phoneNumber: data.phoneNumber,
            email: data.email, changeField: changeField
         }
         await axios.post(`${getBaseUrl}/employer/update-account`, body, config);
         setcurrentStepIndex(3)
      } catch (error) {
         setError(error);
      }
   }

   return (
      <div className='EmpverifyCode '>
         <form onSubmit={handleFormSubmit}>
            <h3 className='title mb-4 text-center'>Enter verification code</h3>
            {
               (changeField === 'phone')
                  ? <p className='message text-center'>
                     Your verification code is sent by WhatsApp to
                     <br />
                     <BsWhatsapp color="#25D366" />  {data.phoneNumber}
                  </p>
                  : <p className="message text-center">
                     Your verification code is sent by Email to
                     <br />
                     <TfiEmail color="#25D366" /> {data.email}
                  </p>
            }


            <div className="d-flex justify-content-center my-5 otp-wrapper h4">
               <OTPInput value={OTP} onChange={setOTP} autoFocus="true" OTPLength={6}
                  otpType="number" disabled={false} />
            </div>
            {error && <p className='text-danger'> {error}</p>}
            <div className='text-center'>
               <button className='btn btn-primary mx-2' >Verify</button>
            </div>
         </form>
      </div>
   )
}

export default EmpOtpForm