import React, { useState, useEffect} from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { TfiEye } from "react-icons/tfi";
import { FaRegEyeSlash } from "react-icons/fa";
import PhoneNumber from '../../../components/PhoneNumber';
import { useLogin } from './useLogin';
import './login.css';


function Login() {
   let { empId = '' } = useParams();

   const navigate = useNavigate();
   const { login, error, isLoading } = useLogin()

   const [phoneNumber, setPhoneNumber] = useState('')
   const [dialingCode, setDialingCode] = useState('')
   const [password, setPassword] = useState('')

   //passward input type
   const [isShown, setIsSHown] = useState(false);
   const togglePassword = () => {
      setIsSHown((isShown) => !isShown);
   };

   useEffect(() => {
      const inputs = document.querySelectorAll('.floating-input');
      
      inputs.forEach(input => {
         if (input.value) {
            input.classList.add('has-value');
         }
      });
   }, []);

   // handle login btn click
   const handleSubmit = async (e) => {
      e.preventDefault();
      await login(dialingCode, phoneNumber, password, empId)
   }




   return (
      <div className='form-login-wrapper'>
         <form className='form-login' onSubmit={handleSubmit}>
            <h2 className='mb-4'>Login</h2>

            {error && <p className='text-danger'>{error}</p>}

            <div className="mb-3 requiredMark">
               <PhoneNumber value={{ phoneNumber, dialingCode }}
                  onChange={{ setPhoneNumber, setDialingCode }} inputDataType="alphaNumber"
                  labelText="Phone Number / Email" />
            </div>
            <div className="mb-3">
               <div className="d-flex">
                  <div className="floating-control requiredMark">
                     <input type={isShown ? "text" : "password"} name="password" id="password" className="floating-input"
                        placeholder="none" autoComplete='off' required
                        onChange={(e) => setPassword(e.target.value)} value={password} />
                     <label htmlFor="password" className="floating-label">Password</label>
                  </div>
                  <span className="input-group-text fs-4 cursor-pointer" onClick={togglePassword}>
                     {isShown ? <FaRegEyeSlash /> : <TfiEye />}
                  </span>
               </div>
            </div>
            <button disabled={isLoading} type="submit" className="btn btn-red btn-login mt-1 mb-3">Login</button>
            <p className='text-center text-sm-end'>
               <span className='text-decoration-underline forgotPasswordText'
                  onClick={() => navigate("/candidate/forget-password")}>Forgot Password?
               </span>
            </p>
         </form>
      </div>
   )
}

export default Login;
