import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useForgotPassword } from './useForgotPassword';
import PhoneNumber from '../../../components/PhoneNumber';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


import "./forgotPassword.css";



function ForgotPassword() {
   const navigate = useNavigate();
   const { forgotPassword, isLoading, error } = useForgotPassword();

   const [phoneNumber, setPhoneNumber] = useState('')
   const [dialingCode, setDialingCode] = useState('')
   const [email, setEmail] = useState('');

   const [selectedOption, setSelectedOption] = useState('phone');
   const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
   };

   // handle reset password click
   const handleSubmit = (e) => {
      e.preventDefault();
      if (selectedOption === 'phone') {
         handlePhoneSubmit();
       } else if (selectedOption === 'email') {
         handleEmailSubmit();
       }
      
   }
   const handlePhoneSubmit = () => {
      // Your logic for handling phone submission
      forgotPassword(dialingCode, phoneNumber,null)
    };

    const handleEmailSubmit = () => {
      // Your logic for handling email submission
      forgotPassword(null,null,email)
    };
   return (
      <div className='form-forgotpassword-wrapper'>
         <h2 className='mb-4'>Forgot Password</h2>
         <form className='form-forgetPassword' onSubmit={handleSubmit}>
            <p>Provide Phone Number / Email to reset your password:</p>
            <RadioGroup
               row
               aria-labelledby="demo-row-radio-buttons-group-label"
               name="row-radio-buttons-group"
            >
               <FormControlLabel value="phone" control={<Radio />} label="phone" checked={selectedOption === 'phone'}
                  onChange={handleOptionChange} />
               <FormControlLabel value="email" control={<Radio />} label="Email" checked={selectedOption === 'email'}
                  onChange={handleOptionChange} />
            </RadioGroup>
            {selectedOption === 'phone' && (
               <div className="mb-3">
                  <PhoneNumber value={{ phoneNumber, dialingCode }}
                     onChange={{ setPhoneNumber, setDialingCode }} inputDataType="alphaNumber"
                     labelText="Phone Number" />
               </div>
            )}
            {selectedOption === 'email' && (
               <div className="mb-3">
                  <div className="floating-control requiredMark">
                     <input type="text" name="email" className="floating-input"
                        placeholder="none" autoComplete='off' required value={email}
                        onChange={(e) => setEmail(e.target.value)}
                     />
                     <label htmlFor="name" className="floating-label">Email</label>
                  </div>
               </div>
            )}
            {error && <div className='text-danger'>{error}</div>}

            <div className='candidate__forgotPassword--btn'>
               <button type="button" className="btn btn-outline-secondary"
                  onClick={() => { navigate('/candidate/login'); }}>Cancel</button>
               <button disabled={isLoading} type="submit" className="btn btn-red">
                  RESET PASSWORD
               </button>
            </div>
         </form>
      </div>
   )

}

export default ForgotPassword;