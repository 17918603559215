import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TfiEye } from "react-icons/tfi";
import { FaRegEyeSlash } from "react-icons/fa";
import { useEmpChangePassword } from './useEmpChangePassword';
import { useNavigate } from 'react-router-dom';
import './empChangePassword.css'




const EmpChangePassword = () => {
   const navigate = useNavigate();

   const { changePassword, isLoading, error } = useEmpChangePassword()

   const [formData, setFormData] = useState({
      oldPassword: '',
      showOldPassword: false,
      newPassword: '',
      showNewPassword: false,
      confirmPassword: '',
      showConfirmPassword: false,
   })

   const [formError, setFormError] = useState('')

   const handleShowPassword = (field) => {
      if (field === 'oldPassword') {
         setFormData({ ...formData, showOldPassword: !formData.showOldPassword });
      }
      if (field === 'newPassword') {
         setFormData({ ...formData, showNewPassword: !formData.showNewPassword });
      }
      if (field === 'confirmPassword') {
         setFormData({ ...formData, showConfirmPassword: !formData.showConfirmPassword });
      }
   }
   const handleInputChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      setFormError('')
   }

   const handleChangePassword = () => {
      const { oldPassword, newPassword, confirmPassword } = formData;
      if (!oldPassword || !newPassword || !confirmPassword) {
         setFormError('All fields are required.'); return 0;
      }

      if (newPassword !== confirmPassword) {
         setFormError('Confirm password is Not Matched.'); return 0;
      }

      changePassword(oldPassword, newPassword, confirmPassword)
   }

   const handleCancel = () => {
      navigate('/employer/profile/home')
   }





   return (
      <div className='emp-changePassword py-5'>
         <Container>
            <h2 className='title'>Change Password</h2>
            <hr />
            <Row className='mt-5 justify-content-center'>
               <Col sm={10} md={6} lg={5} className='order-1 order-lg-0'>
                  <form className='form'>
                     {formError && <p className="flex-grow-1 text-danger">{formError}</p>}
                     {error && <p className="flex-grow-1 text-danger">{error}</p>}

                     <div className="mb-3">
                        <div className="d-flex">
                           <div className="floating-control requiredMark">
                              <input type={formData.showOldPassword ? "text" : "password"} name="oldPassword" id='oldPassword' className="floating-input"
                                 placeholder="none" autoComplete='off' required value={formData.oldPassword}
                                 onChange={handleInputChange} />
                              <label htmlFor="oldPassword" className="floating-label">Old Password</label>
                           </div>
                           <span className="input-group-text fs-4 cursor-pointer" onClick={() => handleShowPassword('oldPassword')}>
                              {formData.showOldPassword ? <FaRegEyeSlash /> : <TfiEye />}
                           </span>
                        </div>
                     </div>
                     <div className="mb-3">
                        <div className="d-flex">
                           <div className="floating-control requiredMark">
                              <input type={formData.showNewPassword ? "text" : "password"} name="newPassword" id='newPassword' className="floating-input"
                                 placeholder="none" autoComplete='off' required value={formData.newPassword}
                                 onChange={handleInputChange} />
                              <label htmlFor="newPassword" className="floating-label">New Password</label>
                           </div>
                           <span className="input-group-text fs-4 cursor-pointer" onClick={() => handleShowPassword('newPassword')}>
                              {formData.showNewPassword ? <FaRegEyeSlash /> : <TfiEye />}
                           </span>
                        </div>
                     </div>
                     <div className="mb-3">
                        <div className="d-flex">
                           <div className="floating-control requiredMark ">
                              <input type={formData.showConfirmPassword ? "text" : "password"} name="confirmPassword" id='confirmPassword' className="floating-input"
                                 placeholder="none" autoComplete='off' required value={formData.confirmPassword}
                                 onChange={handleInputChange} />
                              <label htmlFor="confirmPassword" className="floating-label">Confirm Password</label>
                           </div>
                           <span className="input-group-text fs-4 cursor-pointer" onClick={() => handleShowPassword('confirmPassword')}>
                              {formData.showConfirmPassword ? <FaRegEyeSlash /> : <TfiEye />}
                           </span>
                        </div>
                     </div>
                     <div className='text-center mt-4 d-flex flex-wrap justify-content-center gap-20'>
                        <button type='button' className="btn btn-primary" onClick={handleChangePassword}>Change Password</button>
                        <button type='button' className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                     </div>
                  </form>
               </Col>
            </Row>
         </Container>

      </div>
   )
}

export default EmpChangePassword